<template>
  <div class="userNewFilePreview" v-if="file">
    <img alt="Image" :src="encode_url(file.src)" v-if="fileType === 'image'" @click="mediaView({ src: file.src, type: fileType })" />
    <div v-else-if="fileType === 'video'">
      <video class="newFilePreviewVideo" :src="encode_url(file.src)" controls controlsList="nodownload" />
      <UserNewFileVideoPoster v-if="!isEdit" @poster="(file) => $emit('thumbnail', file)" ref="poster" />
    </div>
  </div>
</template>

<script>
export default {
  props: ["file", "src", "fileType", "isEdit"],
  components: {
    UserNewFileVideoPoster: () => import("./UserNewFileVideoPoster.vue"),
  },
  methods: {
    takeScreenshot: async function() {
      const poster = this.$refs.poster;

      if (!poster) {
        await this.sleep(300);
        return this.takeScreenshot();
      }

      this.$refs.poster.captureThumbnail();
    },
  },
  mounted: function() {
    if (this.isEdit) return;
    this.takeScreenshot();
  },
  watch: {
    file: function() {
      this.takeScreenshot();
    },
  },
};
</script>

<style lang="scss" scoped>
.userNewFilePreview {
  img,
  video {
    max-width: 100%;
  }
}
</style>
